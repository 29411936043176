:root {
  /* Fonts */

  --font-scale: 1;
  --font-size-main: calc(var(--font-scale) * 1em); /* 16px */

  /* Colors */

  --primary-100: #e8f8ff;
  --primary-200: #aee3ff;
  --primary-300: #61baf4;
  --primary-400: #2a8dde;
  --primary-500: #006ad2;
  --primary-600: #0055be;
  --primary-700: #003f9f;
  --primary-800: #1e4176;
  --basic-100: #fff;
  --basic-200: #f7f9fc;
  --basic-300: #edf1f7;
  --basic-400: #c4d0e4;
  --basic-500: #8495ae;
  --basic-600: #657799;
  --basic-700: #556181;
  --basic-800: #222b45;
  --success-100: #e8ffe9;
  --success-200: #c2f5cb;
  --success-300: #9ce3af;
  --success-400: #79c796;
  --success-500: #4ea576;
  --success-600: #27875f;
  --success-700: #27755b;
  --success-800: #185e4e;
  --info-100: #f2f0ff;
  --info-200: #d2cdff;
  --info-300: #9f95ff;
  --info-400: #867aff;
  --info-500: #5d4fff;
  --info-600: #4539db;
  --info-700: #3027b7;
  --info-800: #140f7a;
  --warning-100: #fef1da;
  --warning-200: #fbd59c;
  --warning-300: #f4b56a;
  --warning-400: #ea9545;
  --warning-500: #dd670d;
  --warning-600: #c04800;
  --warning-700: #9f3706;
  --warning-800: #802404;
  --danger-100: #fee6d7;
  --danger-200: #fec6b0;
  --danger-300: #fea088;
  --danger-400: #fd7b6b;
  --danger-500: #fc403a;
  --danger-600: #d82a33;
  --danger-700: #b51d32;
  --danger-800: #92122f;
  --grey-1: #f5f5f5;
  --grey-2: #c4c4c4;
  --grey-3: #808081;
  --grey-4: #4d4d4d;
  --grey-5: #e0e0e0;
  --grey-6: #f3f3f3;
  --grey-7: #8f8f8f;
  --light-grey-1: #ececec;
  --french-grey-1: #f7fafc;
  --french-grey-2: #cbd5e0;
  --french-grey-3: #a0aec0;
  --french-grey-4: #4a5568;
  --french-grey-5: #45546a;
  --green-4: #80c63f;
  --orange-3: #dd8b44;
  --red-1: #e9d7d7;
  --red-2: #e59999;
  --red-3: #b20401;
  --red-4: #e31b23;
  --blue-1: #ccebff;
  --blue-2: #61baf4;
  --blue-3: #2b6cb0;
  --blue-4: #718096;
  --blue-5: #1e4176;
  --blue-6: #002057;
  --pale-blue: #ebf5ff;
  --white: #ffffff;
  --black: #000000;
  --lonestar---primary---primary-900: #0e2164;
  --lonestar---primary---primary-800: #213d9d;
  --lonestar---primary---primary-700: #3c73c7;
  --lonestar---basic---basic-800: #3b3e54;
  --lonestar---basic---basic-700: #6a7f92;
  --lonestar---basic---basic-400: #c5d2e4;
  --lonestar---basic---basic-200: #f5f8fb;
  --lonestar---basic---basic-100: #d9d9d9;
  --lonestar---info---info-600: #19b4e9;
  --lonestar---info---info-300: #bfefff;
  --lonestar---info---info-100: #f4faff;
  --lonestar---success---success-600: #8ec640;
  --lonestar---success---success-100: #f8ffef;
  --lonestar---warning---warning-600: #f78e20;
  --lonestar---danger---danger-700: #e21f26;
  --lonestar---danger---danger-600: #f85e63;
  --lonestar---danger---danger-300: #fedddf;
  --lonestar---danger---danger-100: #fff5f5;
  --lonestar---selection---selection-700: #9747ff;
  --lonestar---selection---selection-600: #cba4ff;
  --lonestar---selection---selection-300: #e9d7ff;

  /* Additional colors */

  --standards-blue: #5b6dd3;
  --standards-orange: #b6533d;
  --standards-yellow: var(--warning-200);
  --standards-purple: #9957b0;
  --standards-red: #be4965;
  --standards-green: #397f5f;
  --standards-grey: #657799;

  /* Typography */

  --typography-h1-font-size: 1.5rem;
  --typography-h1-line-height: 2rem;
  --typography-h1-font-weight: 700;
  --typography-h2-font-size: 1.25rem;
  --typography-h2-line-height: 1.75rem;
  --typography-h2-font-weight: bold;
  --typography-h3-line-height: 1.812rem;
  --typography-h3-font-weight: bold;
  --typography-h4-font-size: 1rem;
  --typography-h4-line-height: 1.625rem;
  --typography-h4-font-weight: bold;
  --typography-h5-font-size: 1rem;
  --typography-h5-line-height: 1.625rem;
  --typography-h5-font-weight: 600;
  --typography-h6-font-size: 1.375rem;
  --typography-h6-line-height: 1.5rem;
  --typography-h6-font-weight: bold;
  --typography-s1-font-size: 0.875rem;
  --typography-s1-line-height: 1.25rem;
  --typography-s1-font-weight: bold;
  --typography-s2-font-size: 0.875rem;
  --typography-s2-line-height: 1.25rem;
  --typography-s2-font-weight: 400;
  --typography-p2-font-size: 0.75rem;
  --typography-p2-line-height: 1.25rem;
  --typography-p2-font-weight: normal;
  --typography-c1-font-weight: 900;
  --typography-c2-font-size: 0.875rem;
  --typography-c2-line-height: 1.625rem;
  --typography-c2-font-weight: normal;

  /* Typography Open Sans */

  --typography-open-sans-font-weight-h5: bold; /* 700 */
  --typography-open-sans-font-size-h5: 1rem; /* 16px */
  --typography-open-sans-line-height-h5: 1.8125rem; /* 29px */
  --typography-open-sans-font-weight-p2: bold; /* 700 */
  --typography-open-sans-font-size-p2: 0.875rem; /* 14px */
  --typography-open-sans-line-height-p2: 1.25rem; /* 20px */

  /* General variables */
  --general-border-radius: var(--global-border-radius);
  --general-line-height: 1.75rem; /* 28px */
  --general-sm-padding-tb: 3px;
  --general-md-padding-tb: 7px;
  --general-lg-padding-tb: 11px;
  --general-sm-font-size: 0.875rem; /* 14px */
  --general-md-font-size: 1rem; /* 16px */
  --general-lg-font-size: 1.25rem; /* 20px */
  --general-font-family: var(--font-type-main);

  /* Button config */
  --button-sm-padding-lr: 19px;
  --button-md-padding-lr: 23px;
  --button-border-width: 1px;
  --button-sm-min-height: calc(
    var(--button-line-height) +
      (var(--button-sm-padding-tb) + var(--button-border-width)) * 2
  );
  --button-md-min-height: calc(
    var(--button-line-height) +
      (var(--button-md-padding-tb) + var(--button-border-width)) * 2
  );
  --button-lg-min-height: calc(
    var(--button-line-height) +
      (var(--button-lg-padding-tb) + var(--button-border-width)) * 2
  );
  --button-sm-strict-indent: calc(
    -1 * (var(--button-line-height) - var(--global-icon-size-small)) / 2 + var(--global-grid-size)
  );
  --button-sm-strict-offset: calc(
    -1 * (var(--button-line-height) - var(--global-icon-size-small)) / 2
  );

  /* Dropdown config */
  --dropdown-content-padding: 0;
  --dropdown-button-padding: 7px;

  /* Dialog config */

  --dialog-width: 800px;
  --dialog-container-margin-top: calc(var(--global-grid-size) * 4);
  --dialog-container-margin-bottom: calc(var(--global-grid-size) * 4);
  --dialog-container-margin: var(--dialog-container-margin-top)
    calc(var(--global-grid-size) * 3) var(--dialog-container-margin-bottom);
  --dialog-header-padding-top: calc(var(--global-grid-size) * 3 - 1px);
  --dialog-header-padding-bottom: calc(var(--global-grid-size) * 2);
  --dialog-header-padding: var(--dialog-header-padding-top)
    calc(var(--global-grid-size) * 4 - 1px) var(--dialog-header-padding-bottom);

  /* --- Project settings --- */

  /* eslint-disable-next-line */
  --page-header-height: calc(var(--global-grid-size) * 9); /* 72px */
  --page-sidebar-width: 320px;
  --page-width-max: 2000px;
  --page-width-min: 320px;
  --content-width-max: 100%;
  --global-grid-size: 8px;

  /* eslint-disable-next-line */
  --global-gap: calc(
    var(--global-grid-size) * var(--font-scale) * 5
  ); /* 40px */

  --global-border-color: transparent;
  --global-border-width: 1px;
  --global-border: var(--global-border-width) solid var(--global-border-color);
  --global-border-radius: 4px;
  --page-content-padding-top: calc(var(--global-grid-size) * 2); /* 16px */
  --page-content-padding-bottom: 0; /* 0px */
  --page-content-header-background: radial-gradient(
      43.63% 89.62% at 65.27% 10.38%,
      #fef8f0 0%,
      rgba(255, 249, 241, 0) 100%
    ),
    linear-gradient(
      103.79deg,
      #fffffd 1.07%,
      #fffffb 20.34%,
      #fffff5 37.87%,
      #fdfdf4 53.2%,
      #f4f9f4 71.32%,
      #e8fdff 100%
    );

  /* Outline */

  --outline-color: var(--blue-2);
  --outline-color-additional: var(--info-100);
  --outline-width: 4px;
  --global-outline: 0 0 0 var(--outline-width) var(--outline-color);
  --global-outline-additional: 0 0 0 var(--outline-width)
    var(--outline-color-additional);

  /* Icon */

  --global-icon-size-xs: 0.5625rem;
  --global-icon-size-sm: 0.85rem;
  --global-icon-size-md: 0.94rem;
  --global-icon-size-lg: 1.03rem;
  --global-icon-size-xl: 1.125rem;
  --global-icon-size-extra-small: 0.875rem; /* 14px */
  --global-icon-size-small: 1rem; /* 16px */
  --global-icon-size: 1.5rem; /* 24px */
  --global-icon-size-large: 1.75rem; /* 28px */

  /* Global shadow */

  --global-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --global-shadow-color: color-mod(var(--primary-400) a(10%));
  --global-shadow-color-hover: color-mod(var(--primary-700) a(15%));
  --card-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --header-shadow: 0px 2px 7px 0px #2b36461a;

  /* Animation */

  --global-transition: all 0.3s ease-out;

  &.font-size-small {
    --font-scale: 0.875; /* 14px */
  }

  &.font-size-medium {
    --font-scale: 1.125; /* 18px */
  }

  &.font-size-large {
    --font-scale: 1.25; /* 20px */
  }

  &.font-size-huge {
    --font-scale: 1.375; /* 22px */
  }

  &.font-size-massive {
    --font-scale: 1.5; /* 24px */
  }

  &.font-type-serif {
    --font-type-main: var(--font-type-additional);
  }

  &.theme-high-contrast {
    @media not print {
      /* Colors */

      --primary-100: #fff;
      --primary-200: #fffd54;
      --primary-300: #6de8fc;
      --primary-400: #fff;
      --primary-500: #6de8fc;
      --primary-600: #fffd54;
      --primary-700: #6de8fc;
      --primary-800: #6de8fc;
      --basic-100: #000;
      --basic-200: #000;
      --basic-300: #000;
      --basic-400: #757575;
      --basic-500: #fff;
      --basic-600: #fff;
      --basic-700: #fff;
      --basic-800: #fff;
      --success-100: #000;
      --success-200: #fff;
      --success-300: #7bed5d;
      --success-400: #7bed5d;
      --success-500: #7bed5d;
      --success-600: #7bed5d;
      --success-700: #7bed5d;
      --success-800: #7bed5d;
      --info-100: #000;
      --info-200: #fff;
      --info-300: #6de8fc;
      --info-400: #6de8fc;
      --info-500: #6de8fc;
      --info-600: #6de8fc;
      --info-700: #6de8fc;
      --info-800: #6de8fc;
      --warning-100: #fff;
      --warning-200: #6de8fc;
      --warning-300: #fffd54;
      --warning-400: #fffd54;
      --warning-500: #fff;
      --warning-600: #fffd54;
      --warning-700: #6de8fc;
      --warning-800: #fffd54;
      --danger-100: #fff;
      --danger-200: #6de8fc;
      --danger-300: #7bed5d;
      --danger-400: #7bed5d;
      --danger-500: #fff;
      --danger-600: #7bed5d;
      --danger-700: #6de8fc;
      --danger-800: #fb50ff;

      /* Project settings */

      --global-border-color: var(--basic-800);
      --global-shadow: none;
      --page-content-header-background: linear-gradient(
        to top,
        var(--basic-800) 1px,
        var(--basic-100) 0px
      );
      --outline-color: var(--info-200);
      --outline-color-additional: var(--outline-color);
      --menu-border-color: var(--basic-800);
    }
  }

  .chakra-collapse {
    overflow: inherit !important;
  }
}
